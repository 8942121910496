import { useEffect, useState } from 'react';

import { getApolloClient, markErrorAsHandled } from '@confluence/graphql';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';
import { useSessionData } from '@confluence/session-data';

import { postJson } from './utils/fetch';
import { withCached } from './utils/with-cached';
import { LicensedProductsQuery } from './graphql/LicensedProductsQuery.graphql';
import {
	LicenseStatus,
	type LicensedProductsQuery as LicensedProductsQueryType,
} from './graphql/__types__/LicensedProductsQuery';

const getTenantLicense = async () => {
	try {
		const licensedProducts = await getApolloClient().query<LicensedProductsQueryType>({
			query: LicensedProductsQuery,
		});

		return (
			licensedProducts.data.tenantContext?.licensedProducts.some(
				(product) =>
					product.productKey === 'loom' && product.licenseStatus === LicenseStatus.ACTIVE,
			) ?? false
		);
	} catch (e) {
		getMonitoringClient().submitError(e, {
			attribution: Attribution.CONTENT_TYPES,
		});
		markErrorAsHandled(e);
		return false;
	}
};

export const getUserPermission = withCached(async (cloudId: string) => {
	try {
		const { permitted } = await postJson(`/gateway/api/permissions/permitted`, {
			permissionId: 'write',
			resourceId: `ari:cloud:loom::site/${cloudId}`,
			checkProductStatus: true,
		});
		return !!permitted;
	} catch (e) {
		getMonitoringClient().submitError(e, {
			attribution: Attribution.CONTENT_TYPES,
		});
		markErrorAsHandled(e);
		return false;
	}
});

type UseLoomPermissionStatusOptions = {
	skip?: boolean;
};

/**
 * WARNING: This is meant to reflect Loom access status once M2.5 launches.
 *
 * For Beta, check `useIsLoomBetaEnabled` instead
 */
export const useLoomPermissionStatus = ({ skip = false }: UseLoomPermissionStatusOptions = {}) => {
	const { cloudId } = useSessionData();
	const [isTenantLicensed, setIsTenantLicensed] = useState(false);
	const [isUserPermitted, setIsUserPermitted] = useState(false);

	useEffect(() => {
		if (skip) {
			return;
		}

		const checkLicensedProducts = async () => {
			setIsTenantLicensed(await getTenantLicense());
		};
		const checkUserPermission = async () => {
			setIsUserPermitted(await getUserPermission(cloudId));
		};

		void checkLicensedProducts();
		if (isTenantLicensed) {
			void checkUserPermission();
		}
	}, [cloudId, isTenantLicensed, skip]);

	return {
		isTenantLicensed,
		isUserPermitted,
	};
};
