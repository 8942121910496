import { isFedRamp } from '@atlassian/atl-context';

import { fg } from '@confluence/feature-gating';
import { isMobileUserAgent, shouldShowMobileWeb } from '@confluence/mobile-detection';

import { useIsLoomBetaEnabled } from './useIsLoomBetaEnabled';
import { useLoomPermissionStatus } from './useLoomPermissionStatus';

export type LoomEntryPointVariant = 'BETA' | 'CO_USE' | 'CROSS_FLOW' | 'CROSS_JOIN' | 'DISABLED';

export const useLoomEntryPointVariant = (): LoomEntryPointVariant => {
	const isInFedRamp = isFedRamp();
	const isMobile = isMobileUserAgent() || shouldShowMobileWeb();
	const isBetaEnabled = useIsLoomBetaEnabled();

	const skipPermsCheck = isInFedRamp || isMobile || isBetaEnabled;

	const { isTenantLicensed: tenantHasLoom, isUserPermitted: userHasLoom } = useLoomPermissionStatus(
		{ skip: skipPermsCheck || !fg('confluence_frontend_loom_cross_sell') },
	);

	if (isInFedRamp) {
		return 'DISABLED';
	}

	// don't enable entry points if user is on a mobile device or viewing mobile web
	if (isMobile) {
		return 'DISABLED';
	}

	if (isBetaEnabled) {
		return 'BETA';
	}

	if (!fg('confluence_frontend_loom_cross_sell')) {
		return 'DISABLED';
	}

	if (!tenantHasLoom) {
		return 'CROSS_FLOW';
	}

	if (!userHasLoom) {
		return 'CROSS_JOIN';
	}

	return 'CO_USE';
};
